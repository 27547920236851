.package-details__sec {
  /* background-image: url("../../../util/booking/package-bg.jpg"); */
  background:linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0)), url('../../../util/PackageBackground.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 100%;
  /* background-color: black; */
  min-height: 95vh;
  margin-bottom: 0px;
  gap: 10px;
}

.package-details__sec .package-details {
  max-width: 1200px;
  margin: auto;
  display: flex;
  padding: 200px 10px;
  justify-content: space-between;
}

.package-details__sec .package-img__cover {
  width: 48%;
}

.package-details__sec .package-img {
  /* width: 100%;
  object-fit: cover;
  height: 460px; */
  width: 100%;
  height: auto;
}

.package-details__sec .package-details__cover {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 10px 5px;
  color: white;
}

.package-details__sec .package-details__price,
.package-details__sec .package-details__title {
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 36px;
}

.package-details__sec .package-details__title-line {
  background-color: #9B87EE;
  border-radius: 10px;
  height: 4px;
  margin: 15px auto 30px auto;
  width: 50px;
}

.package-details__sec .package-details__content {
  color: white;
  text-align: center;
  opacity: 0.9;
}

.package-details .pacakge-details__btn-cover {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  padding: 20px 0px;
}

.toast-msg__cover .btn,
.package-details .btn {
  padding: 4px 0px;
  width: 160px;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  border: 2px solid #9B87EE;
  color: white;
  transition: all 0.1s ease-in;
  cursor: default;
}

.package-details .btn-quantity {
  background-color: #9B87EE;
  display: flex;
  padding: 0px 10px;
  justify-content: flex-end;
  align-items: center;
}

.package-details .btn-quantity .btn-minus,
.package-details .btn-quantity .btn-plus {
  border: none;
  padding: 0;
  background-color: transparent;
  color: white;
  font-size: 150%;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
  transition: all 0.1s ease-in;
  cursor: pointer;
}

.toast-msg__cover .btn__view-cart,
.package-details .btn-quantity .btn-minus:hover,
.package-details .btn-quantity .btn-plus:hover {
  filter: brightness(80%);
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.package-details .btn-quantity .btn-quantity-text {
  border-right: 1px solid white;
  padding-right: 6px;
  height: 100%;
  display: flex;
  align-items: center;
}

.package-details .btn-quantity .btn-quantity-value {
  color: white;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.package-details .btn-add-to-cart {
  cursor: pointer;
}

.toast-msg__cover .btn__checkout:hover,
.package-details .btn-add-to-cart:hover {
  background-color: #9B87EE;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.toast-msg__cover {
  background-color: black;
  max-width: 350px;
  padding: 10px;

  align-items: center;
  gap: 5px;
}

.toast-msg__cover .toast-msg__hero-sec {
  display: flex;
  background-color: black;
}

.toast-msg__cover .toast-msg__text-cover {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* width: 65%; */
  text-align: center;
}

.toast-msg__cover .toast-msg__title {
  color: white;
  font-size: 90%;
  margin-bottom: 5px;
}
.error_msg{
  text-align: center;
  width: 100%;
  font-size:20px;
  color: white;
}

.toast-msg__cover .toast-msg__content {
  color: white;
  opacity: 0.6;
  font-size: 80%;
}

.toast-msg__cover .toast-msg__btn-cover {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  padding: 10px 0px;
}

.toast-msg__cover .btn__view-cart {
  cursor: pointer;
  background-color: #9B87EE;
  text-transform: uppercase;
  font-size: 70%;
  width: 120px;
  padding: 8px 0px;
}

.toast-msg__cover .btn__checkout {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 70%;
  width: 120px;
  padding: 8px 0px;
}

@media screen and (max-width: 764px) {
  .package-details__sec .package-details {
    flex-direction: column;
  }

  .package-details__sec .package-img__cover {
    width: 100%;
  }

  .package-details__sec .package-details__cover {
    width: 100%;
    padding-top: 30px;
  }
}



.toast_btn {
  text-decoration: none;
  padding: 4px 0px;
  width: 160px;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  border: 2px solid #9B87EE;
  color: white;
  transition: all 0.1s ease-in;
  cursor: pointer;
  
}
.toast_btn:hover{
  background-color: #9B87EE;
  color:white
}




.button{
  background-color: transparent;
  border: 1px solid #9B87EE ;
  padding: 0 20px;
  border-radius: 20px;
  color: white;
}
.button:hover{
  background-color: #9B87EE;

}
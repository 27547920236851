.cart-sec {
  background:linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0)), url('../../../util/homeMobileBackground.png');

    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: 100%;
    background-color: #010020;

    min-height: 90vh;
    margin-bottom: 0px;
  }
  
  .cart-sec .cards {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    font-family: "Roboto Condensed", sans-serif;
    max-width: 1000px;
    margin: auto;
  }
  
  .cart_card{
    display: flex;
    margin-bottom: 20px;
    background-color: rgb(0, 0, 0);

    justify-content: space-between;
    align-items: center;
width: 100%;
    gap: 5px;
  }



  .block_1{
padding: 0 50px;
border-left: 2px  solid rgba(128, 128, 128, 0.521);
border-right: 2px  solid rgba(128, 128, 128, 0.521);
  }

  .block_2{
  
      }
h2{
  text-align: center;
  font-size: medium;
}

h3{    
    font-family: "Roboto Condensed", sans-serif;
    text-transform: uppercase;
    text-align: center;
}


.title__bottom-line {
    background-color: #9B87EE;
    border-radius: 10px;
    height: 5px;
    margin: 15px auto 30px auto;
    width: 50px;
  }

  .btn   , .btn:hover{
    display: flex;
    justify-content: space-between;
    padding: 0px 4px;
    width: 160px;
    border-radius: 50px;
    border: 2px solid #9B87EE;
    color: white;
    transition: all 0.1s ease-in;
    cursor: default;
  }
  button{
    border-radius: 50px;
text-align: center;
    background-color: black;
    color: white;
    border: none;
  }
.flex{
    display: flex;

    gap: 5px;
    align-items: center;
    align-self:  center;
}
  .btn-quantity-text{
    text-align: center; 
    padding: 0px 10px;
    border-right: 2px solid #9B87EE;
  }
  .cross{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 2px solid #9B87EE;
    cursor: pointer;
    margin-right: 10px;
  }
  .checkout button{
    text-decoration: none;
    padding: 4px 0px;
    width: 160px;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    border: 2px solid #9B87EE;
    color: white;
    transition: all 0.1s ease-in;
    cursor: pointer;
    background-color: #9B87EE;
    float: right;
    margin: 15px 0px;
  }
  .checkout button:hover{
    background-color: transparent;
    color:white
  }
  @media screen and (max-width: 800px){
   .cart_card{
        display: flex;
        flex-direction: column;
    gap: 15px;
    padding: 15px 0;

    }
  
}

@media screen and (max-width: 500px){
  .checkout {
    text-align: center;
    margin:0 auto
  }
}
.Contact-page{
    background-image: url('../../util/serviceBackground.jpg');
    width: 100%;
    height: 100%;
    background-size: cover;
}

.contact-mobile-title, .contact-mobile-text{
    display: none;
}

#contact-mobile{
    display: none;
}

.contact-logo{
    display: none;
}

.contact{
    display: flex;
    justify-content: space-around;
    padding: 180px 0 50px;
    max-width: 1500px;
    margin: 0 auto;
}

.contact-right-top-title{
    font-size: 25px; 
    font-family: 'Fjalla One';   
}

.contact-right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

iframe{
    margin: auto 0;
    padding: 20px;
}

.contact-right-bottom-text{
    display:flex;
}

.contact-fb{
    padding-right: 10px;
}

.contact-right-top-text{
    color: #BDBDBD;
    padding-top: 2px;
    font-family: 'Open Sans';
}

@media screen and (max-width: 1200px) {
    .contact{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }


    #contact-mobile{
        display:inline-block;
    }
    
    #contact-desktop{
        display: none;
    }

    .contact-logo{
        display: inline-block;
        margin-bottom: 30px;
    }

    .contact-right-top{
        padding: 10px 0;
    }

    .contact-right-bottom-text{
        display: flex;
    }

    .contact-right-bottom-text{
        justify-content: center;
    }

    .contact-right-top-title{
        font-size: 20px;    
    }

    .contact-mobile-title{
        display: inline-block;
        font-family: 'Fjalla One';
    }

    .contact-mobile-text{
        display: inline-block;
        font-family: 'Open Sans';
        color: white;
    }
}

@media screen and (max-width: 991px) {
}

/* @media screen and (max-width: 650px) {
    iframe{
        width: 550px !important;
        height: 400px !important;
    }
}

@media screen and (max-width: 570px) {
    iframe{
        width: 450px !important;
        height: 300px !important;
    }
} */
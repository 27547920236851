.package-card__box {

  margin: 20px;
  max-width: 300px;
  min-width: 290px;
  /* height: 450px; */
  padding-bottom: 10px;
  background-color: #010020;
  border-radius: 5px;
  color: white;
}

.package-card__box .card-img {
  width: 100%;
  height: auto;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  object-fit: unset;
  aspect-ratio: 3/4;
}

.package-card__box .card-title {
  text-align: center;
  text-transform: uppercase;
  padding: 18px 0px 10px 0px;
  font-size: 110%;
  margin: 5px;
  font-family: "Roboto Condensed", sans-serif;
}

.package-card__box .card-content {
  text-align: center;
  font-size: 85%;
  padding: 0px 10px;
}

.package-card__box .card-link__cover {
  display: flex;
  justify-content: center;
}

.package-card__box .card-link {
  text-align: center;
  border: 2px solid #9B87EE;
  font-size: 80%;
  color: white;
  text-transform: uppercase;
  border-radius: 100px;
  padding: 5px 0px;
  width: 150px;
  text-decoration: none;
  transition: all 0.1s ease-in;
}

.package-card__box .card-link:hover {
  background-color: #9B87EE;
  color: white;
}

.About-page {
    background:linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.5)), url('../../util/aboutBackground.jpg');
    width: 100%;
    height: 100%;
    background-size: cover;
}

.about-page-main {
    width: 100%;
    text-align: center;
    padding-top: 180px;
    padding-bottom: 50px;
}

.about-mobile{
    display: none;
}
.about-top-description{
    text-align: justify;
    max-width: 1200px;
    margin: 0 auto;
    color: #BDBDBD;
}



@media screen and (max-width: 991px){
    .about-mobile-description{
        padding: 0 50px;
        color: #BDBDBD;
    }
    .mobile-background{
        background:linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../../util/homeBanner.jpg');
        width: 100%;
        height: 550px;
        background-size: cover;
    }
    .about-mobile{
        display: block;
    }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.go2072408551 {
  background-color: black !important;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 1px 2px 5px rgba(255, 255, 255, 0.2);
  max-width: none !important;
}

.text-center{
  text-align: center;
}
.text-no-underline{
  text-decoration: none!important;
}
.t-18{
  font-size:18px;
}
.rainbox-border {
  background-image: linear-gradient( 226.4deg,  rgba(255,26,1,1) 28.9%, rgba(254,155,1,1) 33%, rgba(255,241,0,1) 48.6%, rgba(34,218,1,1) 65.3%, rgba(0,141,254,1) 80.6%, rgba(113,63,254,1) 100.1% );
  position: relative;
}


.rainbox-border::before {
  content:'';
  position: absolute;
  left:20px;
  top:20px;
  z-index: 0;
  width:calc(100% - 40px);
  height:calc(100% - 40px);
  background-color: #000;
}

.sp-box{
  width:500x;
  height:300px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  margin:0 auto;
  padding: 10%;
} 

.sp-box *{
  text-align: left;
  z-index: 2;
  font-weight: bold;
  /* text-shadow: 1px -1px #477e47; */
}

.sp-box h4{
  margin-bottom: 2rem;
}

.sp-container{
  place-items: center;
  display: grid;
}

.rendeHtml p{
  margin-bottom: 0.2rem;
}

.carousel .slide img.slider-logo {
  max-width: 40rem;
  width:80%
}

body .ril-caption{
  justify-content: center;
}
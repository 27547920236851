.App{
    width: 100%;
    color: white;
}

.mobile-title-line{
    display: none;
}

.title-line{
    background-color: #9B87EE;
    border-radius: 10px;
    height: 5px;
    margin: 15px auto 30px auto;
    width: 50px;
}

.App{
    background-color: black;
  }
  
  .home-icons{
    position: fixed;
    top:40%;
    right: 0;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
  }
  
  .social-link{
    margin: 20px 0 0 0;
  }
  
  .social-btn{
    border: 1px solid white !important;
    border-radius: 50%;
    padding: 10px;  
  }
  
  .social-text{
    color: white;
  }

  .cart_nav{
    position: relative;
    text-align: center;
  }
  
  .cart_style .social-btn{
    text-decoration: none;
    display: inline-flex;
    width: 40px;
    padding: 0;
    aspect-ratio: 1;
    justify-content: center;
    align-items: center;
  }
  
  .cart_length{
    background-color: red;
    left: 100%;
    transform: translate(-50%, -50%);
    color:white;
    position: absolute;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

@media screen and (max-width: 991px) {
    .title-line{
        display: none;
    }
    .mobile-title-line{
        display: block;
        background-color: #9B87EE;
        border-radius: 10px;
        height: 5px;
        margin: 5px auto;
        width: 50px;
    }
    .cart_nav .cart_style{
      position: relative;
    }
   .cart_style .social-btn{
    margin-top:1rem;
  }
  
  .cart_length{
    left: 100%;
    top:-10px;
  }
}
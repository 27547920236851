.package-sec {
  background:linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.5)), url('../../util/aboutBackground.jpg');

  width: 100%;
  height: 100%;
  background-size: cover;
}

.package-cards {
  display: flex;
  flex-flow: row wrap;
  -webkit-box-pack: center;
  justify-content: center;
  font-family: "Roboto Condensed", sans-serif;
  padding: 180px 0 100px 0;
}

.Gallery-page{
  background-image: url('../../util/galleryBackground.jpg');
  width: 100%;
  background-size: cover;
}
.gallery{
  padding: 180px 1.5rem 175px;
  width: 100%;
  max-width: 1500px;
  margin: auto;
}
.gallery-top{
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: start;
}
.video-gallery{
  width:100%;
  max-width: 52vw;
}
.gallery-mobile-title1, .gallery-mobile-title2, .gallery-mobile-description1{
  display: none;
}

.gallery-bottom-title{
  padding-top: 50px;
}



.gallery-mobile{
  display: none;
}

.gallery-title{
  font-family: 'Fjalla One';
}

.gallery-description{
  font-family: 'Open Sans';
  color: #BDBDBD;
}

.galleryVideo1{
  width: 65%;
  height: 100%;
}

.gallery-top-right{
  padding-left: 50px;
  width: 35%;
}

.gallery-top-right-title{
  font-family: 'Fjalla One';
}

.gallery-top-right-description{
  font-family: 'Open Sans';
  color: white;
}

.gallery-top-right-date{
  color: #94908C;
}

.gallery-mb{
  display: none;
}

.gallery-top-right2{
  padding-top: 30px;
}

.gallery-top-right2-title{
  padding-top: 30px;
}

.gallery-top-2{
  display: flex;
  justify-content: space-between;
  padding-top: 75px;
}

.gallery-video{
  width: 100%;
  height:225px;
}

.galleryVideo2{
  width: 100%;
}

.gallery-bottom-1, .gallery-bottom-2{
  display: flex;
  justify-content: space-between;
}

.gallery-bottom-photo{
  padding: 15px;
}

.galleryVideo{
  width: 100%;
}



@media screen and (max-width: 1600px){


  .gallery-top-right{
      padding-left: 20px;
  }

  .gallery-top-right2{
      padding-top: 0;
  }
}

@media screen and (max-width: 1500px){
  .gallery-top{
      flex-direction: column;
      align-items: center;
  }

  .gallery-big{
      text-align: center;
      padding-bottom: 20px;
  }

  .gallery-top-right-description{
      padding: 0 100px;
  }

  .gallery-top-right{
      text-align:center;
      padding-left: 0;
      padding-top: 30px;
      width: 100%;
  }

  .gallery-top-right2{
      text-align:center;
      padding-left: 0;
      padding-top: 30px;
      width: 100%;
  }
  .galleryVideo1{
      width: 100%;
  }
  .galleryVideo2{
      width: 100%;
  }
}

@media screen and (max-width: 991px){
  .gallery-top{
      padding-top: 20px;
  }
  .gallery-description{
      font-size: 16px;
      color: white;
      padding-top: 20px;
  }
  .gallery-top-right{
      padding-top: 0;
  }
  .gallery-top-right2{
      padding-top: 50px;
  }
  .gallery-top-right-description{
      display: none;
  }
  .gallery-top-right-title{
      display: none;
  }
  /* .gallery-top-right-date{
      float: left;
      padding-top: 10px;
  } */
  .gallery-mobile-title1{
      font-family: 'Fjalla One';
      display: inline-block;
      width: 100%;
  }

  .gallery-mobile-title2{
      float: left;
      font-size: 16px;
  }

  .gallery-top-right2-title{
      display: none;
  }

  .gallery-mobile-date1{
      float: left;
      color: #94908C;
      font-family: 'Open Sans';
      padding-top: 10px;
  }

  .gallery-mobile-description1{
      font-family: 'Open Sans';
      display: inline-block;
      color: white;
      font-size: 16px;
  }
  /* .gallery-desktop{
      display: none;
  } */

  .gallery-date{
      font-family: 'Open Sans';
      color: #94908C;
      padding-bottom: 25px;
  }
  .gallery-bottom{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 0px;
  }
  .gallery-bottom-photo{
      width: 100%;
  }
}
@media screen and (max-width: 575px){
  .video-gallery{
      max-width: 100vw;
  }
}
/* General Styles */
.Footer {
  padding: 30px 0;
}

#graffiti2 {
  margin-left: 100px;
  padding-top: 10px;
  width: 300px;
}

.footer-bottom {
  border-top: solid 1px white;
  padding-top: 25px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-left {
  color: grey;
}

.footer-copyright-bottom {
  font-size: 20px;
}

.rowwidth {
  width: 50%;
}

.footer-right {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-right: 100px;
  flex-wrap: wrap;
}

.linkdeco {
  text-decoration: none;
  color: gray;
}

.footer-right1-content {
  font-family: "Mulish";
  width: 100%;
}

.footer-right3-logo {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-bottom: 50px;
}

.footer-top-right {
  display: flex;
  width: 60%;
  margin-top: 20px;
  margin-right: 150px;
  justify-content: space-around;
}

.footer-top-right-button {
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.footer-top-right-button:hover {
  color: pink;
}

/* Mobile Styles */
.footer-mobile {
  display: none;
}

@media screen and (max-width: 1440px) {
  .footer-top {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .footer-top,
  .footer-bottom {
    display: none;
  }

  .footer-mobile {
    display: block;
  }

  .footer-mobile-top {
    text-align: center;
    border-bottom: 1px solid white;
    margin: 0 1.5rem;
  }

  .footer-mobile-title1 {
    padding: 20px 0;
  }

  .footer-mobile-description {
    color: gray;
    padding: 10px 0;
    font-family: "Mulish";
  }

  .footer-mobile-icon {
    padding: 10px 20px 30px 20px;
  }

  .footer-mobile-middle {
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid white;
    margin: 0 1.5rem;
  }

  .footer-mobile-bottom {
    text-align: center;
  }

  .footer-mobile-middle .col-6 {
    display: inline-block;
    width: 50%;
    padding: 5px 0;
  }
}

.checkout__sec {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)),
    url("../../util/homeMobileBackground.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 100%;
  background-color: black;
  min-height: 80vh;
  margin-bottom: 0px;
  gap: 10px;
  font-family: "Hind Siliguri", sans-serif;
}
.container_checkout {
  max-width: 1500px;
  margin: auto;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.container_checkout h1 {
  font-size: xx-large;
}
.subcontainer {
  width: 100%;
}

article {
  background-color: black;
  width: 100%;
}
.order_status {
  padding: 0 40px;
}

h1 {
  text-align: center;
  padding: 10px 0;
  font-family: "Bangla MN";
  text-transform: uppercase;
  padding-top: 40px;
}

.subcontainer h1 {
}

.booking_container {
  display: flex;
  margin-bottom: 15px;
  gap: 10px;
}

.booking_container .d-flex {
  width: 50%;
}

.booking_container .package_img-container,
.booking_container .info-content {
  width: 50%;
}

.booking_container .package_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.text-container {
  width: 100%;
}

.text-container > p {
  padding: 0 20px;
  text-align: center;
}

table {
  width: 100%;
}

td {
  padding: 20px 0;
}

article > .total {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 40px 20% 40px 40px;
}
.total > h2 {
  font-size: larger;
}

.label > span {
  color: red;
}
input {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px black inset !important;
  -webkit-text-fill-color: white;
}

.information {
  padding: 15px 40px;
}

.submit_button {
  background-color: orange;
  width: 100%;
  margin: 20px 0;
  padding: 10px 0;
}

.information_input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 35px;
  padding: 20px 15px;
}

.pin-card-field {
  padding: 20px 15px;
}

/* Add styling to the calendar icon */
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Invert the color of the icon */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}

.container_checkout .pay-btn {
  margin-left: 14px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #9b87ee;
  font-size: 80%;
  color: white;
  text-transform: uppercase;
  border-radius: 100px;
  padding: 5px 0px;
  width: 150px;
  text-decoration: none;
  transition: all 0.1s ease-in;
}

.container_checkout .pay-btn:hover {
  background-color: #9b87ee;
  color: white;
}

.CardBrandIcon.is-loaded svg {
  background-color: white;
}

.StripeElement {
  border-bottom: 1px solid white;
  padding-bottom: 3px;
}

.modal-out {
  animation: popDownToBottom 0.3s ease forwards;
}

.pay-btn .loader {
  width: 25px;
  padding: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #2dd5bc;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}

@media screen and (max-width: 991px) {
  .container_checkout {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 600px) {
  .booking_container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .booking_container .package_img-container,
  .booking_container .info-content {
    width: 100%;
  }

  .booking_container .package_img-container {
    display: flex;
    justify-content: center;
  }

  .package_img {
    max-width: 400px;
    margin: auto;
  }

  .information_input {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 400px) {
  .information {
    padding: 0 15px;
  }
}

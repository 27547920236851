.Menu-page{
    background:linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.5)), url('../../util/menuBackground.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
}

.Menu1{
    padding:0 1.5rem;
    padding-top: 180px;
}



.menu-bottom1{
    display: flex;
    justify-content: space-around;
    width: 100%;
    min-height: 100vh;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 75px;
    padding-bottom: 100px;
}



@media screen and (max-width: 991px) {
    .Menu1{
        padding-left:0px;
        padding-right:0px;
    }
    .menu-middle{
        flex-wrap: wrap;
    }

    .menu-bottom-row{
        width: 100%;
        justify-content: space-around;
    }

    .menu-bottom1{
        width: 100%;
    }

    .menu-bottom-col{
        width: 100%;
        flex-direction: column;
    }

    .menu-mobile-middle{
        display: block;
    }

    .menu-mobile-row1{
        display: flex;
        justify-content: space-around;
        width:100%;
    }

    .menu-middle-button{
        width: 30%;
        background-color: #9B87EE99;
        border-radius: 50px;
        margin: 1%;
        border: 0px;
        padding: 0rem 0;
        font-size: 16px;

    }
    .menu-middle-button.active,.menu-middle-button:hover{
        background-color: #9B87EE;
        color:#fff;
    }

    .menu-mobile-button{
        color: white;
    }
}

@media screen and (max-width: 575px) {

    .menu-middle-button{
        
    }
}
.title__sec {
  padding-top: 200px;
}

.title__sec > .title {
  font-family: "Bangla MN";
  text-align: center;
  text-transform: uppercase;
}

.title__sec > .title__bottom-line {
  background-color: #9B87EE;
  border-radius: 10px;
  height: 5px;
  margin: 15px auto 30px auto;
  width: 50px;
  
}

.entry{
  text-align: center;
  font-size: small;
}


@media screen and (max-width: 500px){
  .title__sec {
    padding-top: 100px;
  }
}
.Service-page {
    background:linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.5)), url('../../util/serviceBackground.jpg');
    width: 100%;
    height: 100%;
    background-size: cover;
}

.service-title{
    display:none;
    font-family: 'Fjalla One';
    margin: 0 auto;
    padding-bottom: 50px;
    text-align: center;
}

.service{
    width: 100%;
    min-height: 100vh;
    max-width: 1400px;
    margin: 0 auto;
    padding: 180px 0px;
}

.service-image {
    position: relative;
    width: 50%;
}

.service-photo1,
.service-photo2 {
    display: block;
    width: 100%;
    filter: brightness(75%);
    margin-top: 40px;
}
.service-photo1{
    padding: 20px;
}
.image__overlay {
    position: absolute;
    top: 5.5%;
    left: 4%;
    width: 92%;
    height: 89%;
    color: #ffffff;
    font-size: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
    user-select: none;
}

.image__overlay > * {
    transform: translateY(20px);
    transition: transform 0.2s;
}

.image__overlay:hover{
    opacity: 1;
    font-size: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.image__title {
    font-size: 2em;
    font-weight: bold;
    font-size: 72px;
    position: absolute;
    bottom: 8%;
    right: 8%;
    font-family: 'East Sea Dokdo', cursive;
}

.image__overlay:hover > * {
    transform: translateY(0);
    font-size: 144px;
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    width: 100%;
    text-align: center;
}



@media screen and (max-width: 1450px) {

    .service{
        max-width: 1000px;
    }

    .service-title{
        display: block;
    }
    .image__title {
        font-size: 54px;
    }
    
    .image__overlay:hover > * {
        font-size: 108px;
    }

}



@media screen and (max-width: 992px) {
    .service-image {
        width:80%;
        margin:0 auto;
    }
    .service-title{
        padding-bottom:10px;
    }
}



.header{
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 30%);
    width: 100%;
    z-index: 100;
}

.navbar {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    width: 100%;
}

.nav-a-link{
    font-family: 'Open Sans';
    color: white;
}

.graffiti3{
    width: 100px;
}

.nav-button, .nav-button1 {
    color: white;
    text-decoration: none;
    height: 20px;
}

.mobile-left:focus {
    outline: none;
    box-shadow: none;
  }

.nav-button:hover {
    color: pink;
}

.container{
    max-width: 1000px;
}

#responsive-navbar-nav{
    justify-content: space-around;
}

.mobile-center-logo{
    display: none;
}

@media screen and (max-width: 991px) {
    .mobile{
        display:flex;
        text-align: center;
        width: 100%;
    }
    .nav-button{
        display: none;
    }
    .mobile-center-logo{
        display: block;
        width: 100%;
        margin: auto;
    }
    .header{
        background-color: black;
    }

    .nav-a-link{
        border-bottom: solid 1px white;
        padding: 25px;
        font-family: 'Antic Didone';
        color: white !important;
    }
    
    .navbar-toggler-icon{
        color: white !important;
    }

    .nav-b-link{
        display: none;
    }
    .mobile_left_logo{
        width: 50%;
        margin: 0 50px;
    }
    .mobile-left{
        border: none;
    }
    .zincLogo{
        height: 75px;
        padding-right: 40px;
    }
}